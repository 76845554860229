<template>
  <div>
    <section>
      <v-snackbar v-model="snackbar.model" timeout="3000">{{
        snackbar.text
      }}</v-snackbar>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-form
                v-model="form1.isValid"
                @submit.prevent="updateGeneralConfig"
              >
                <Subheader icon="mdi-cog" title="Allgemein"></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading" :disabled="form1.isLoading">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <event-deadline-config
                            :fristInMin.sync="
                              availabilityConfig.defaultDeadlineInMinutes
                            "
                          ></event-deadline-config>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="availabilityConfig.showAllRequestsInList"
                              label="Anzeigen von allen Abfragen der Organisation in Übersicht erlauben"
                              hint="Wenn diese Option deaktiviert ist, werden in der Übersicht nur Abfragen angezeigt, die keine Tielnahmebeschränkung haben oder an denen der Benutzer teilnehmen darf. Über einen direkten Link zu den Abfragedetails kann die Veranstaltung weiterhin geteilt und aufgerufen werden."
                              persistent-hint
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :disabled="!form1.isValid"
                          :loading="form1.isLoading"
                          @click="updateGeneralConfig()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import EventDeadlineConfig from "@/components/scheduling/EventDeadlineConfig.vue";
// import { db } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { UPDATE_AVAILABILITY_CONFIG } from "@/store/action-types.js";

export default {
  name: "availability-config-tab-general",
  components: {
    Subheader,
    EventDeadlineConfig,
  },
  data() {
    return {
      loading: true,
      snackbar: {
        model: false,
        text: "",
      },
      form1: {
        isValid: false,
        isLoading: false,
      },

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        min: (value) =>
          /^[+]?\d+([.]\d+)?$/.test(value) || "Muss positive Zahl sein.",
      },
    };
  },
  computed: {
    availabilityConfig() {
      return this.$store.state.organization.activeOrganization.config
        .availability;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loading = false;
    },

    updateGeneralConfig() {
      if (this.form1.isValid) {
        this.form1.isLoading = true;
        this.$store
          .dispatch(`${AVAILABILITY}${UPDATE_AVAILABILITY_CONFIG}`, {
            organizationId: this.$route.params.organizationId,

            type: "onlyGeneralConfig",
            defaultDeadlineInMinutes:
              this.availabilityConfig.defaultDeadlineInMinutes,
            showAllRequestsInList:
              this.availabilityConfig.showAllRequestsInList,
          })
          .then(() => {
            this.form1.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Einstellungen erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.form1.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = error.message;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
